import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import { HomeModule } from './home/home.module';
import {AppRoutingModule} from "./app.routes";
import {LoginComponent} from "./components/login/login.component";
import {authInterceptor} from "./auth/auth.interceptor";



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HomeModule,
    LoginComponent,
  ],
  providers: [provideHttpClient(withInterceptors([
    authInterceptor
  ]))],
  bootstrap: [AppComponent],
})
export class AppModule {}
