import {Component, inject} from '@angular/core';
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {Router, RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {AuthService} from "../../auth/auth.service";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatError,
    ReactiveFormsModule,
    MatButton,
    MatIconButton,
    MatInput,
    MatLabel,
    RouterLink,
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  authService = inject(AuthService);
  router = inject(Router);
  loginForm: FormGroup;
  fb = inject(FormBuilder)
  errorMessage: string | null = null;
  hidePassword: boolean = true;


  constructor() {

    this.loginForm = this.fb.group({
      name: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }


  login() {
   this.authService.login(this.loginForm.value.name,this.loginForm.value.password);
  }

  logout() {

  }




  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
}


