<div class="login-page">
  <div class="login-card">
    <div class="card-title">
      <h1>Login</h1>
    </div>
    <form class="form-group" [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="name" formControlName="name" placeholder="Name">
        <mat-error *ngIf="loginForm.get('name')?.hasError('required') && loginForm.get('name')?.touched">
          Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          placeholder="Password"
          autocomplete="current-password"
        >
        <button mat-icon-button type="button" (click)="togglePasswordVisibility()">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched">
          Password is required
        </mat-error>
      </mat-form-field>

      <div class="error">
        <mat-error *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>
      </div>

      <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">Submit</button>
    </form>
  </div>
</div>
